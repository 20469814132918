<template>
  <v-dialog :value="dialog" max-width="300">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        outlined
        v-on="on"
        :width="$vuetify.breakpoint.xsOnly ? '100' : ''"
      >
        {{ $t("accounts.connect") }}</v-btn
      >
    </template>
    <form
      action
      data-vv-scope="imap-form"
      @submit.prevent="validateForm('imap-form')"
    >
      <v-card>
        <v-card-title><u>IMAP</u></v-card-title>
        <v-card-text>
          <br />
          <v-row justify="center">
            <v-col cols="12" class="py-0">
              <label v-text="$t('email')"></label>
              <v-text-field
                outlined
                dense
                v-model="imap.email"
                data-vv-validate-on="blur"
                v-validate="'required|email|min:2'"
                :error-messages="errors.collect('imap-form.email')"
                data-vv-name="email"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <label v-text="$t('password')"></label>
              <v-text-field
                outlined
                dense
                v-model="imap.password"
                :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="show = !show"
                :type="show ? 'text' : 'password'"
                data-vv-validate-on="blur"
                v-validate="'required|min:2'"
                :error-messages="errors.collect('imap-form.password')"
                data-vv-name="password"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="$emit('cancel')"
            outlined
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
            ><v-icon style="margin-right: 5px" size="14px">$save</v-icon
            >{{ $tc("save", { name: "" }) }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>
<script>
export default {
  name: "IMAPDialog",
  props: ["imap"],
  data() {
    return { show: false, dialog: false };
  },
  methods: {
    validateForm(scope) {
      //console.log("validando");
      this.errors.clear();
      this.$validator.validateAll(scope).then((result) => {
        if (result) this.$emit("validated");
        //else console.log("ERROR DE VALIDACION");
      });
    },
  },
};
</script>
<style scoped lang="sass">
label
  line-height: 3
  display: block
  height: 25px
</style>
